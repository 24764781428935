var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('th',[_vm._v("Institusi")]),_c('td',[_vm._v(": "+_vm._s(_vm.data.nama_institusi))]),_c('td'),_c('th',[_vm._v("Program Studi")]),_c('td',[_vm._v(": "+_vm._s(_vm.data.nama_prodi))])]),_c('tr',[_c('th',[_vm._v("Kelompok")]),_c('td',[_vm._v(": "+_vm._s(_vm.data.profesi))]),_c('td'),_c('th',[_vm._v("Tanggal Awal")]),_c('td',[_vm._v(": "+_vm._s(_vm.formatDate(_vm.data.tanggal_awal)))])]),_c('tr',[_c('th',[_vm._v("Stase/ Prodi")]),_c('td',[_vm._v(": "+_vm._s(_vm.data.nama)+", "+_vm._s(_vm.data.stase))]),_c('td'),_c('th',[_vm._v("Tanggal Akhir")]),_c('td',[_vm._v(": "+_vm._s(_vm.formatDate(_vm.data.tanggal_akhir)))])])])]},proxy:true}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.headers,"items":_vm.table.items,"options":_vm.tableOptions,"server-items-length":_vm.table.totalItems,"loading":_vm.table.loading},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.stase",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nama)+", "+_vm._s(item.stase)+" ")]}},{key:"item.tanggal_awal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.tanggal_awal))+" ")]}},{key:"item.tanggal_akhir",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.tanggal_akhir))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return undefined}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.getDataFromApi()}}},[_vm._v(" Reload ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }