<template>
	<v-card flat class="">
		<v-simple-table dense>
			<template v-slot:default>
				<tbody>
					<tr>
						<th>Institusi</th>
						<td>: {{ data.nama_institusi }}</td>
						<td></td>
						<th>Program Studi</th>
						<td>: {{ data.nama_prodi }}</td>
					</tr>
					<tr>
						<th>Kelompok</th>
						<td>: {{ data.profesi }}</td>
						<td></td>
						<th>Tanggal Awal</th>
						<td>: {{ formatDate(data.tanggal_awal) }}</td>
					</tr>
					<tr>
						<th>Stase/ Prodi</th>
						<td>: {{ data.nama }}, {{ data.stase }}</td>
						<td></td>
						<th>Tanggal Akhir</th>
						<td>: {{ formatDate(data.tanggal_akhir) }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
		<v-data-table :headers="table.headers" :items="table.items" :options.sync="tableOptions"
			:server-items-length="table.totalItems" :loading="table.loading" class="elevation-1">
			<template v-slot:item.stase="{ item }">
				{{ item.nama }}, {{ item.stase }}
			</template>
			<template v-slot:item.tanggal_awal="{ item }">
				{{ formatDate(item.tanggal_awal) }}
			</template>
			<template v-slot:item.tanggal_akhir="{ item }">
				{{ formatDate(item.tanggal_akhir) }}
			</template>
			<template v-slot:item.actions="{ item }">
				<!-- <v-icon small @click="deleteItem(item)">
					{{ icons.mdiDelete }}
				</v-icon> -->
			</template>
			<template v-slot:no-data>
				<v-btn small color="primary" @click="getDataFromApi()">
					Reload
				</v-btn>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
import PilihMultiUser from '@/views/PilihMultiUser.vue'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiDelete, mdiPencil } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
	components: {
		PilihMultiUser,
	},
	data() {
		return {
			praktik_id: this.$route.params.id,
			data: {
				nama_institusi: '',
				nama_prodi: '',
				profesi: '',
				stase: '',
				tanggal_awal: '',
				tanggal_akhir: '',
			},
			icons: {
				mdiDelete,
				mdiPencil,
			},
			tableOptions: {},
			table: {
				loading: true,
				headers: [
					{
						text: 'No',
						align: 'start',
						sortable: false,
						value: 'no',
					},
					{
						text: 'Nama', value: 'nama', sortable: false
					},
					{
						text: 'Email', value: 'email', sortable: false
					},
					{
						text: 'Role', value: 'role', sortable: false
					},
					{ text: '', value: 'actions', sortable: false },
				],
				items: [],
				totalItems: 0,
			},
			dialog: false,
			form: {
				editedIndex: -1,
				editedItem: {
					role: '',
					selectedUsers: [],
				},
				defaultItem: {
					role: '',
					selectedUsers: [],
				},
				roles: [
					'PESERTA DIDIK', 'PEMBIMBING KLINIK'
				],
				users: [],
			},
		}
	},
	watch: {
		tableOptions: {
			handler() {
				this.getDataFromApi()
			},
			deep: true,
		},
	},
	computed: {
		formTitle() {
			return this.form.editedIndex === -1 ? 'Tambah Item' : 'Edit Item'
		},
	},
	created() {
		this.getDetail()
	},
	methods: {
		save() {
			if (this.form.editedIndex > -1) {
			} else {
				const fmData = new FormData()
				fmData.append('praktik_id', this.praktik_id)
				fmData.append('role', this.form.editedItem.role)
				fmData.append('users', this.form.editedItem.selectedUsers)
				axiosPostAuth('api/Praktik/addUserPraktik', fmData)
					.then(response => {
						if (response.code === 200) {
							this.getDataFromApi()
							this.dialog = false
						}
						else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			}
		},
		getDataFromApi() {
			const { page, itemsPerPage } = this.tableOptions
			const fmData = new FormData()
			fmData.append('praktik_id', this.praktik_id)
			fmData.append('page', page)
			fmData.append('itemsPerPage', itemsPerPage)
			fmData.append('search', '')
			axiosPostAuth('api/Praktik/getUserPraktik_datatable', fmData)
				.then(response => {
					this.table.items = response.data
					this.table.totalItems = response.total
					this.table.loading = false
				})
				.catch(error => {
					alert(error)
				})
		},
		add() {
			this.form.editedItem = Object.assign({}, this.form.defaultItem)
			this.form.editedIndex = -1
		},
		editItem(item) {
			this.form.editedIndex = this.table.items.indexOf(item)
			this.form.editedItem = Object.assign({}, item)
			this.dialog = true
		},
		// childMessageReceived(arg) {
		// 	console.log(arg);
		// 	this.dialogSelectUser = false
		// },
		getUserBelumSelesai() {
			const fmData = new FormData()
			fmData.append('praktik_id', this.praktik_id)
			fmData.append('profesi', this.data.profesi)
			fmData.append('role', this.form.editedItem.role)
			axiosPostAuth('api/Praktik/getUserBelumSelesai', fmData)
				.then(response => {
					this.form.users = response.data
					this.form.editedItem.selectedUsers = []
				})
				.catch(error => {
					alert(error)
				})
		},
		getDetail() {
			const fmData = new FormData()
			fmData.append('id', this.praktik_id)
			axiosPostAuth('api/Praktik/getPraktik', fmData)
				.then(response => {
					this.data = Object.assign({}, response.data)
					this.getUserBelumSelesai()
				})
				.catch(error => {
					alert(error)
				})
		},
		deleteItem(item) {
			const conf = confirm("Apakah yakin akan dihapus?")
			if (conf) {
				const fmData = new FormData()
				fmData.append('id', item.id)
				axiosPostAuth('api/Praktik/deleteUserPraktik', fmData)
					.then(response => {
						if (response.code === 200) {
							this.getDataFromApi()
						}
						else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			}
		},
	},
}
</script>